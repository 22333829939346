import React, { useEffect } from "react";
import {
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { AddArticleImg, sendIcon } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import constants from "../../utilities/constants";
import Select from "./../Select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "./../../hooks/hook-form/FormProvider";
import {
  createArticles,
  emptyArticleContent,
  getAllCategories,
  updateArticle,
} from "../../slices/helpdesk";
import { EditorState } from "draft-js";
import Input from "../Input";
import { openModal } from "../../slices/modals";
import { useHistory, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
const AddArticle = ({ id, open, handleClose, data }) => {
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const { t } = useTranslation();
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "#fff" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };

  const { pathname } = useLocation();
  const history = useHistory();
  const defaultValues = {
    title: "",
    articleCategory: "",
    articleStatus: "",
  };
  useEffect(() => {
    let defaultValues = {};
    defaultValues.title = pathname.includes("/edit")
      ? data?.article?.title
      : "";
    defaultValues.articleCategory = pathname.includes("/edit")
      ? data?.article?.category?._id
      : "";
    defaultValues.articleStatus = pathname.includes("/edit")
      ? data?.article?.status
      : "";
    0;
    methods.reset({ ...defaultValues });
  }, [data]);
  const addArticleSchema = Yup.object().shape({
    title: Yup.string()
      .strict(false)
      .trim()
      .min(2, "Must be longer than 2 characters")
      .required("Title is required"),
    articleCategory: Yup.string().required("Category is required"),
    articleStatus: Yup.string().required("Status is required"),
  });
  const { articleContent, func } = useSelector((state) => state.helpdesk);

  const methods = useForm({
    resolver: yupResolver(addArticleSchema),
    defaultValues,
  });
  const { user } = useSelector((state) => state.user);
  const { categories } = useSelector((state) => state.helpdesk);
  useEffect(() => {
    if (categories.length === 0 && open)
      dispatch(getAllCategories(user.websiteID));
  }, [user.websiteID, open]);
  const onSubmit = async (values) => {
    try {
      if (data?.article !== undefined) {
        dispatch(
          updateArticle({
            websiteID: user?.websiteID,
            id: data.article._id,
            data: {
              title: values.title,
              content:
                articleContent === "" ? data?.article.content : articleContent,
              category: values.articleCategory,
              status: values.articleStatus,
            },
          })
        ).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            showSnackbar({
              variant: "success",
              message: t("Article updated Successfully"),
            });
            handleClose(id);
            history.push(pathname.split("/")[2]);
          }
        });
      } else {
        dispatch(
          createArticles({
            websiteID: user?.websiteID,
            data: {
              title: values.title,
              content: articleContent,
              category: values.articleCategory,
              status: values.articleStatus,
            },
          })
        ).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            reset({
              defaultValues,
            });
            func(EditorState.createEmpty());
            showSnackbar({
              variant: "success",
              message: t("Article successfully created"),
            });
            dispatch(emptyArticleContent());
            history.push(pathname.split("/")[2]);
            handleClose(id);
          }
        });
      }
    } catch (err) {
      showSnackbar({
        variant: "error",
        message: t(err.message),
      });
    }
  };

  const categoriesOptions = categories.map((item) => {
    return {
      label: item.name,
      value: item._id,
    };
  });
  const { handleSubmit, reset, resetField, formState, register } = methods;
  return (
    <Dialog open={open} onClose={() => handleClose(id)} className="info-modal">
      <LazyLoadImage
        src={AddArticleImg}
        className="assign-icon"
        alt="AddArticleImg"
      />
      <DialogTitle className="assign-operator-title">
        {" "}
        {t(
          (pathname.includes("/edit") ? "Update" : "Create a new") +
            " " +
            "Article"
        )}
      </DialogTitle>
      <DialogContentText className="assign-operator-text">
        {t(
          `In order to ${
            pathname.includes("/edit") ? "update" : "push"
          } your article to helpdesk you need to setup all this preferences`
        )}
      </DialogContentText>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div id="select-category">
            <Input
              label={t("Title")}
              placeholder={t("Title")}
              name={"title"}
              errors={formState.errors}
              register={register}
            />
            <Select
              data={categoriesOptions}
              name={"articleCategory"}
              label={t("Article Category")}
              id="add-article"
              errors={formState.errors}
              control={methods.control}
              value={defaultValues.articleCategory}
            />

            <Select
              data={constants.ARTICLES_STATUS}
              name={"articleStatus"}
              label={t("Article Status")}
              id="article-status"
              errors={formState.errors}
              control={methods.control}
              value={defaultValues.articleStatus}
            />
          </div>
          <DialogActions className="assign-operator-btns">
            <Button
              className="assign-operator-cancel-btn"
              onClick={() => {
                if (data?.article !== undefined) {
                  handleClose(id);
                } else {
                  const title = methods.watch("title", false);
                  const category = methods.watch("articleCategory", false);
                  handleClose(id);
                  dispatch(
                    openModal("draft-article", {
                      title: title,
                      content: articleContent,
                      category: category,
                      status: "DRAFT",
                    })
                  );
                  reset({
                    defaultValues,
                  });
                }
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              className="assign-operator-change-btn"
              color="primary"
              type="submit"
            >
              <LazyLoadImage src={sendIcon} style={{ marginRight: "5px" }} />
              {pathname.includes("/edit") ? t("Update") : t("Create")}
            </Button>
          </DialogActions>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default AddArticle;
